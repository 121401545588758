<template>
  <div class="classification">
    <div>
      <searchBar :search-out="searchData" />
      <treeSelect class="treeSelect" :options="dataList">
        <div v-for="(item,index) in typeTwo" :key="index" class="classification_top">
          <h2 class="classification_name">{{ item.title }}</h2>
          <div class="classification_ov">
            <div
              v-for="(son, sN) in item.ThreeLevel"
              :key="sN"
              class="classification_list"
              @click="btnTypeTwo(son)"
            >
              <div class="classification_list_img">
                <img :src="son.goodTypeImg" alt="">
              </div>
              <div class="classification_title">{{ son.goodTypeName }}</div>
            </div>
          </div>
        </div>
      </treeSelect>
    </div>
  </div>
</template>

<script>
import treeSelect from '@/component/treeSelect'
import searchBar from '@/component/head/search'
import { getSelfCategoryFirstLevel } from '@/services/api'

export default {
  name: 'ClassificationOfGoods',
  components: {
    treeSelect,
    searchBar
  },
  data() {
    return {
      searchData: {
        isShowSearch: true, // 输入框 title 控制
        bgColor: '#fff', // 背景颜色
        isShowReturn: true, // 返回 上一页 显隐
        callbackGo: this.callbackGo, // 点击返回 上一页 回调
        isSearch: '', // input val
        placeholder: '搜索商品', // placeholder
        callbackDown: this.callbackInputGo // 搜索 路由页面

      },
      dataList: {
        active: 0,
        items: [],
        clickNav: this.changeTabIndex,
        list: [],
        goodsParams: {
          id: '', // 左侧菜单栏的接口返回ID
          page: 1, // 默认分页数
          pageSize: 10, // 默认分页显示数量
          loading: false, // 是否显示loading
          finished: false, // 是否显示数据已经加载完
          error: false, // 是否显示数据加载错误
          changePage: this.changePageList
        }
      },
      typeTwo: '',
      listId: ''

    }
  },
  mounted() {
    this.getSelfCategoryFirstLevel()
  },
  methods: {
    // 返回上一页
    callbackGo: function() {
      this.$router.go(-1)
    },
    // 搜索
    callbackInputGo: function(val) {
      if (val.trim()) {
        // 存储搜索历史_丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅___________
        const newKeyList = JSON.parse(
          window.localStorage.getItem('searchHistoryKey')
        )
        this.searchHistoryList = newKeyList.length >= 1 ? newKeyList : []
        if (this.searchHistoryList.indexOf(val) < 0) {
          this.searchHistoryList.unshift(val)
          const localObj = JSON.stringify(this.searchHistoryList)
          window.localStorage.setItem('searchHistoryKey', localObj)
        }
        // 存储搜索历史_丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄___________
        this.$router.push({
          path: '/goodsList',
          query: {
            searchKeyword: val,
            // pddList
            fromPage: 'goodsSearch'
          }
        })
      }
    },
    getSelfCategoryFirstLevel: function() {
      const data = {
        channelId: 1
      }
      getSelfCategoryFirstLevel(data).then((res) => {
        if (Number(res.code) === 200) {
          res.data.typeFirst.forEach((e) => {
            e.text = e.goodTypeName
          })
          this.dataList.items = res.data.typeFirst
          this.typeTwo = res.data.typeTwo
        }
      })
    },
    // list
    changePageList: function(e) {
    },
    // tab
    changeTabIndex: function(index) {
      this.dataList.items[index].goodTypeId
      this.listId = this.dataList.items[index].goodTypeId
      this.listData(this.listId)
    },
    // 调转二级
    btnTypeTwo: function(item) {
      this.$router.push({
        path: '/ficationList',
        query: {
          'keyword': item.goodTypeName,
          'goodTypeId': item.goodTypeId
        }
      })
    },
    listData: function(goodsTypeId) {
      this.goodsTypeId = goodsTypeId
      const data = {
        channelId: 1,
        goodsTypeId: this.goodsTypeId
      }
      getSelfCategoryFirstLevel(data).then((res) => {
        if (Number(res.code) === 200) {
          this.typeTwo = res.data
        }
      })
    }
  }

}
</script>

<style scoped lang="less">
.classification {
  /deep/ .van-tree-select__content {
    background: #f6f6f6;
  }

  /deep/ .search_input {
    border: none;
    background: #e2dddd;
  }

  /deep/ .search_go_input {
    //background: #e2dddd;
    color: #000;
  }

  /deep/ .van-tree-select__nav {
    background: #ecebeb;
  }

  /deep/ .van-tree-select__content {
    background: #f6f6f6;;
  }

  background: #f6f6f6;

  .classification_top {
    //width: 100%;
    background: #fff;
    border-radius: 6px;
    margin: 10px;

    .classification_name {
      text-align: left;
      margin-left: 10px;
      padding-top: 12px;
      box-sizing: border-box;
    }

    .classification_ov {
      overflow: hidden;
      padding: 10px;
      box-sizing: border-box;

      .classification_list {
        width: 70px;
        height: 100px;
        float: left;
        margin-right: 10px;

        .classification_list_img {
          width: 70px;
          height: 70px;
          background: #fff;
        }

        .classification_title {
          margin-top: 8px;
          font-size: 10px;
          text-align: center;
        }
      }

      .classification_list:nth-child(3n) {
        margin-right: 0px;
      }
    }
  }
}

</style>
